








































import { Vue, Component, ModelSync } from "vue-property-decorator";

import { HydroEconomicFilterFormInterface, ComboBoxItem } from "@/interfaces";

@Component
export default class HydroEconomicFilterForm extends Vue {
    @ModelSync("HydroEconomicFilterForm", "change", { type: Object })
    readonly formValues!: HydroEconomicFilterFormInterface;

    demandUnits: ComboBoxItem[] = [
        {
            text: this.$t("hydroEconomicFilterForm.agriculture"),
            value: "agriculture"
        },
        { text: this.$t("hydroEconomicFilterForm.urban"), value: "urban" },
        {
            text: this.$t("hydroEconomicFilterForm.industry"),
            value: "industry"
        },
        { text: this.$t("hydroEconomicFilterForm.golf"), value: "golf" },
        { text: this.$t("hydroEconomicFilterForm.wetland"), value: "wetland" }
    ];

    periods: ComboBoxItem[] = [
        {
            text: this.$t("hydroEconomicFilterForm.monthlyMonthly"),
            value: "monthlyMonthly"
        },
        {
            text: this.$t("hydroEconomicFilterForm.monthlyDaily"),
            value: "monthlyDaily"
        },
        { text: this.$t("hydroEconomicFilterForm.daily"), value: "daily" }
    ];

    onHydroEconomicFilterUpdated() {
        this.$emit("on-hydro-economic-filter-updated");
    }
}
